<template>
  <div class="layout5">
    <div class="left-content">
      <div class="head">
        <div class="head-a">
          <dynamic-template name="Logo"></dynamic-template>
        </div>
        <div class="head-b">
          <dynamic-template name="HeaderBar"></dynamic-template>
        </div>
      </div>

      <div class="content-col">
        <dynamic-template :name="mainTemplate"></dynamic-template>
      </div>
    </div>
    <div class="right-content">
      <dynamic-template name="Weather3"></dynamic-template>
      <dynamic-template name="Currency3"></dynamic-template>
    </div>

    <dynamic-template name="FooterBar"></dynamic-template>
  </div>
</template>

<script>
import DynamicTemplate from '../components/DynamicTemplate';

export default {
  name: 'layout5',
  props: ['data'],
  components: { DynamicTemplate },
  computed: {
    mainTemplate() {
      return this.$store.state.mainTemplate;
    }
  }
};
</script>

<style lang="scss">
body {
  padding: 15px 30px 0;
  background: url('/images/bg/bg_layout.png') center no-repeat !important;
  background-size: cover;
}
.item{
  margin-top: 79px !important;
    margin-left: 102px !important;
}
.left-content {
  width: 1445px;
  display: inline-block;
  margin-right: 24px;

  .head {
    width: 1435px;
    height: 149px;
    background: url('/images/parts/bg-head.png') center no-repeat;
    background-size: cover;
    margin-bottom: 16px;

    .head-a {
      display: inline-block;
      vertical-align: middle;
      width: 330px;
      height: 119px;
      margin: 15px 24px;
    }
    .head-b {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 388px);
    }

    .logo {
      text-align: center;
      display: inline-block;

      img {
        width: 96%;
        height: 119px;
        -o-object-fit: contain;
        object-fit: contain;
        object-position: center;
      }
    }
    .header-bar {
      max-width: 1047px;
      margin-left: -2pxpx;
      color: white;
      font-size: 32px;
      font-family: Montserrat-SemiBold;
    }
  }
}
.right-content {
  width: 390px;
  display: inline-block;
}

.content-col {
  position: relative;
  width: 1438px;
  height: 813px;
  border: solid 5px white;
  overflow: hidden;
}
.instagram-main,
.weather-single,
.prayer-time,
.pharmacy {
  zoom: 0.72;
  -moz-transform: scale(0.72);
}
</style>

<style>
.instagram-main .card-image {
    width: 100% !important;
    height: 100% !important;
    position: unset !important;
}
.instagram-main .page-background {
    width: 60% !important;
}
.instagram-main .mainDetails {
    width: 40% !important;
    height: 100%;
    position: absolute !important;
}
.instagram-main .mainDetails .info
{
  visibility: visible !important;
}
</style>
